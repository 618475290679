import Layout from "./common/layout"
import Head from "./common/Head"
import Store from "./product/Store/Store"
import { useFormatter } from "helpers/i18n"

const ProductStore = () => {
  const { currentLocale } = useFormatter()
  return (
    <>
      <Head
        showCaptcha={true}
        title={
          currentLocale === "en"
            ? "No. 1 Multi-channel E-commerce Solution for SME Sellers"
            : "Quản lý thương mại điện tử đa kênh dành cho nhà bán hàng"
        }
        description={
          currentLocale === "en"
            ? "OctoSells, a leading multi-channel e-commerce management solution with exclusive features. Easy to manage products, orders, and inventory in real-time."
            : "OctoSells là giải pháp quản lý bán hàng đa kênh dễ dàng và hiệu quả trên các sàn TMĐT. Quản lý tập trung đơn hàng và sản phẩm. Đồng bộ tồn kho tức thời."
        }
        image={
          "https://s3.ap-southeast-1.amazonaws.com/public.onpoint.vn/product-1-meta.png"
        }
      />
      <Layout>
        <Store />
      </Layout>
    </>
  )
}

export default ProductStore
